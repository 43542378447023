var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-line" }, [
        _c(
          "span",
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.createAppImage } },
              [_vm._v("添加应用镜像")]
            ),
            _c(
              "el-button",
              {
                attrs: { plain: "", icon: "el-icon-refresh-right" },
                on: { click: _vm.findAppImagePage },
              },
              [_vm._v("刷新")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        [
          _c("finalTable", {
            ref: "finalTableRef",
            attrs: { datas: _vm.dataset },
            on: { tableEventHandler: _vm.tableEventHandler },
            scopedSlots: _vm._u([
              {
                key: "fileSizeSlot",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.size2Str(row.datas.fileSize)) +
                          "\n        "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.driver.addDialogVisible,
            "before-close": _vm.cancelDeviceSave,
            width: "728px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.driver, "addDialogVisible", $event)
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _vm._v("\n      " + _vm._s(_vm.driver.title) + "\n    "),
          ]),
          _c(
            "el-form",
            {
              ref: "deviceForm",
              attrs: {
                "label-position": "top",
                model: _vm.driver.form,
                rules: _vm.driver.formRules,
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 40 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("vlink.app.imageName"),
                            prop: "imageName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: this.currentState === "edit",
                              maxlength: "32",
                              "show-word-limit": true,
                              clearable: "",
                              placeholder: _vm.$t("commons.pleaseInput"),
                            },
                            model: {
                              value: _vm.driver.form.imageName,
                              callback: function ($$v) {
                                _vm.$set(_vm.driver.form, "imageName", $$v)
                              },
                              expression: "driver.form.imageName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("vlink.app.tagName"),
                            prop: "tagName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: this.currentState === "edit",
                              maxlength: "32",
                              "show-word-limit": true,
                              clearable: "",
                              placeholder: _vm.$t("commons.pleaseInput"),
                            },
                            model: {
                              value: _vm.driver.form.tagName,
                              callback: function ($$v) {
                                _vm.$set(_vm.driver.form, "tagName", $$v)
                              },
                              expression: "driver.form.tagName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 40 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("vlink.tsl.description"),
                            prop: "description",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "32",
                              "show-word-limit": true,
                              clearable: "",
                              placeholder: _vm.$t("commons.pleaseInput"),
                            },
                            model: {
                              value: _vm.driver.form.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.driver.form, "description", $$v)
                              },
                              expression: "driver.form.description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("vlink.app.extendConfig"),
                            prop: "extendConfig",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: this.currentState === "edit",
                              maxlength: "32",
                              "show-word-limit": true,
                              clearable: "",
                              placeholder: _vm.$t("commons.pleaseInput"),
                            },
                            model: {
                              value: _vm.driver.form.extendConfig,
                              callback: function ($$v) {
                                _vm.$set(_vm.driver.form, "extendConfig", $$v)
                              },
                              expression: "driver.form.extendConfig",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 40 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("vlink.app.jarName"),
                            prop: "jarName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: true,
                              maxlength: "32",
                              "show-word-limit": true,
                              clearable: "",
                              placeholder: _vm.$t("commons.pleaseInput"),
                            },
                            model: {
                              value: _vm.driver.form.jarName,
                              callback: function ($$v) {
                                _vm.$set(_vm.driver.form, "jarName", $$v)
                              },
                              expression: "driver.form.jarName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.uploadLoading,
                      expression: "uploadLoading",
                    },
                  ],
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "镜像文件", prop: "fileList" } },
                    [
                      _c("div", { staticClass: "temolate-list-container" }, [
                        _c(
                          "div",
                          { staticClass: "title-line" },
                          [
                            _c("span", [_vm._v("文件名称")]),
                            _c("span", [_vm._v("文件大小")]),
                            _c("span", [_vm._v("文件格式")]),
                            _vm.currentState != "edit"
                              ? [_c("span", [_vm._v("操作")])]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm.fileList.length === 0
                          ? _c("div", { staticClass: "no-data-line" }, [
                              _c("span", [_vm._v("暂无数据")]),
                            ])
                          : _c(
                              "div",
                              _vm._l(_vm.fileList, function (item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "detail-line" },
                                  [
                                    _c("span", [_vm._v(_vm._s(item.fileName))]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.size2Str(item.fileSize))
                                      ),
                                    ]),
                                    _c("span", [
                                      _vm._v(_vm._s(item.fileFormat)),
                                    ]),
                                    _vm.currentState != "edit"
                                      ? [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "el-icon-delete",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.fileSingleDelete(
                                                    index
                                                  )
                                                },
                                              },
                                            }),
                                          ]),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              }),
                              0
                            ),
                      ]),
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            "on-success": _vm.uploadSuccess,
                            "on-error": _vm.uploadFail,
                            "before-upload": _vm.beforeUpload,
                            disabled: this.currentState === "edit",
                            "show-file-list": false,
                            "with-credentials": true,
                            "file-list": _vm.fileList,
                            accept: ".jar",
                            limit: 1,
                            action: _vm.uploadPath,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "upload-handler-line",
                              style: {
                                cursor:
                                  _vm.fileList.length >= 1
                                    ? "not-allowed"
                                    : "pointer",
                              },
                            },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    disabled: _vm.fileList.length >= 1,
                                    effect: "dark",
                                    content: "最多支持上传1个服务模板",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-upload" }),
                                  _c("span", [_vm._v("点击上传附件")]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancelDeviceSave } }, [
                _vm._v(_vm._s(_vm.$t("commons.cancel"))),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleDeviceSave },
                },
                [_vm._v(_vm._s(_vm.$t("commons.save")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }