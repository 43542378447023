<template>
  <div class="app-container">
    <div class="filter-line">
      <span>
        <el-button type="primary" @click="createAppImage"
          >添加应用镜像</el-button
        >
        <el-button plain icon="el-icon-refresh-right" @click="findAppImagePage"
          >刷新</el-button
        >
      </span>
    </div>
    <div>
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      >
        <template v-slot:fileSizeSlot="row">
          <span>
            {{ size2Str(row.datas.fileSize) }}
          </span>
        </template>
      </finalTable>
    </div>

    <!-- 添加驱动对话框 -->
    <el-dialog
      :visible.sync="driver.addDialogVisible"
      :before-close="cancelDeviceSave"
      width="728px"
      :close-on-click-modal="false"
    >
      <template slot="title">
        {{ driver.title }}
      </template>
      <el-form
        label-position="top"
        :model="driver.form"
        ref="deviceForm"
        :rules="driver.formRules"
      >
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item :label="$t('vlink.app.imageName')" prop="imageName">
              <el-input
                :disabled="this.currentState === 'edit'"
                v-model="driver.form.imageName"
                maxlength="32"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('vlink.app.tagName')" prop="tagName">
              <el-input
                v-model="driver.form.tagName"
                :disabled="this.currentState === 'edit'"
                maxlength="32"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item
              :label="$t('vlink.tsl.description')"
              prop="description"
            >
              <el-input
                v-model="driver.form.description"
                maxlength="32"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('vlink.app.extendConfig')"
              prop="extendConfig"
            >
              <el-input
                v-model="driver.form.extendConfig"
                :disabled="this.currentState === 'edit'"
                maxlength="32"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item :label="$t('vlink.app.jarName')" prop="jarName">
              <el-input
                v-model="driver.form.jarName"
                :disabled="true"
                maxlength="32"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-loading="uploadLoading">
          <el-form-item label="镜像文件" prop="fileList">
            <div class="temolate-list-container">
              <div class="title-line">
                <span>文件名称</span>
                <span>文件大小</span>
                <span>文件格式</span>
                <template v-if="currentState != 'edit'">
                  <span>操作</span>
                </template>
              </div>
              <div v-if="fileList.length === 0" class="no-data-line">
                <span>暂无数据</span>
              </div>
              <div v-else>
                <div
                  v-for="(item, index) in fileList"
                  :key="index"
                  class="detail-line"
                >
                  <span>{{ item.fileName }}</span>
                  <span>{{ size2Str(item.fileSize) }}</span>
                  <span>{{ item.fileFormat }}</span>
                  <template v-if="currentState != 'edit'">
                    <span>
                      <i
                        class="el-icon-delete"
                        @click="fileSingleDelete(index)"
                      ></i>
                    </span>
                  </template>
                </div>
              </div>
            </div>
            <el-upload
              class="avatar-uploader"
              :on-success="uploadSuccess"
              :on-error="uploadFail"
              :before-upload="beforeUpload"
              :disabled="this.currentState === 'edit'"
              :show-file-list="false"
              :with-credentials="true"
              :file-list="fileList"
              :accept="'.jar'"
              :limit="1"
              :action="uploadPath"
            >
              <div
                class="upload-handler-line"
                :style="{
                  cursor: fileList.length >= 1 ? 'not-allowed' : 'pointer',
                }"
              >
                <el-tooltip
                  :disabled="fileList.length >= 1"
                  class="item"
                  effect="dark"
                  content="最多支持上传1个服务模板"
                  placement="top"
                >
                  <i class="el-icon-upload"></i>
                  <span>点击上传附件</span>
                </el-tooltip>
              </div>
            </el-upload>
          </el-form-item>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cancelDeviceSave">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button type="primary" @click="handleDeviceSave">{{
          $t("commons.save")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  createAppImage,
  editAppImage,
  deleteAppImage,
  findAppImage,
  publishAppImage,
  useAppImage,
} from "@/api/ruge/vlink/app/app";
import finalTable from "@/components/FinalTable";
import { envInfo } from "@/constants/envInfo";
import { size2Str } from "@/utils/utils";
export default {
  name: "appImageManager",
  components: {
    finalTable,
  },
  created() {
    const { appId, appDriverName } = this.$route.query;
    this.driver.listQuery.appId = appId;
    this.driver.form.imageName = appDriverName;
    this.findAppImagePage();
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  data() {
    var validatorFileList = (rule, value, callback) => {
      if (this.fileList.length === 0) {
        callback(new Error("请上传服务模板！"));
      } else {
        callback();
      }
    };
    return {
      loadingFlag: false,
      uploadLoading: false,
      currentState: null,
      fileList: [],
      uploadPath:
        envInfo.bgApp.archivePath +
        "/param/archive/upload?ulType=DefaultUpload&scopeType=ALL",
      downloadPath:
        envInfo.bgApp.archivePath +
        "/param/archive/download?dlType=DefaultDownload&fi=",
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: false,
        },
        header: [
          {
            prop: "imageName",
            label: this.$t("vlink.app.imageName"),
            width: "",
          },
          {
            prop: "description",
            label: this.$t("vlink.tsl.description"),
            width: "",
          },
          {
            prop: "tagName",
            label: this.$t("vlink.app.tagName"),
            width: "",
          },
          {
            prop: "extendConfig",
            label: this.$t("vlink.app.extendConfig"),
            width: "",
          },
          {
            prop: "jarName",
            label: this.$t("vlink.app.jarName"),
            width: "",
          },
          {
            prop: "fileSize",
            label: this.$t("vlink.app.fileSize"),
            width: "",
          },
          {
            prop: "publishStatus",
            label: this.$t("vlink.app.publishStatus"),
            width: "",
          },
          {
            prop: "operation",
            label: this.$t("commons.actions"),
            width: "200",
          },
        ],
        tableData: [],
        searchLineConfig: {},
        // 表格内容配置
        detailConfig: {
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "publish",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "发布",
                hideProp: "publishStatus",
                hideValue: "Y",
              },
              {
                actionType: "iconClick",
                eventName: "download",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "下载",
              },
              {
                actionType: "iconClick",
                eventName: "app",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "应用",
                hideProp: "publishStatus",
                hideValue: "N",
              },
              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
          fileSize: {
            type: "slot",
            slotName: "fileSizeSlot",
          },
          publishStatus: {
            type: "enumerationColumn",
            emuList: {
              Y: "已发布",
              N: "未发布",
            },
          },
        },
        advanceFilterConfig: {},
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      driver: {
        title: null,
        total: 0,
        list: null,
        listLoading: false,
        addDialogVisible: false,
        listQuery: {
          current: 1,
          rowCount: 10,
          tenantId: null,
          appId: null,
        },
        form: {
          appImageId: null,
          appId: null,
          fileId: null,
          fileSize: null,
          jarName: null,
          imageName: null,
          tagName: null,
          extendConfig: null,
          description: null,
        },
        formRules: {
          imageName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          tagName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          jarName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
        },
      },
    };
  },
  methods: {
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.findAppImagePage();
      } else if (datas.type === "paginationChange") {
        this.driver.listQuery.current = datas.params.current.page;
        this.driver.listQuery.rowCount = datas.params.current.limit;
        this.findAppImage();
      } else if (datas.type === "updateSelectionList") {
        this.device.multipleSelection = datas.list;
      } else if (datas.type === "switchEvent") {
        this.updateEnableStatus(datas.row);
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "publish":
            console.log(datas.row);
            this.loadingFlag = true;
            publishAppImage(datas.row)
              .then(() => {
                // 成功提示
                this.$message({
                  message: "发布成功",
                  type: "success",
                });
                debugger;
                // this.driver.listQuery.current = datas.params.current.page;
                // this.driver.listQuery.rowCount = datas.params.current.limit;
                this.findAppImage();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              })
              .finally(() => {
                this.loadingFlag = false;
              });
            break;
          case "download":
            window.location.href = this.downloadPath + datas.row.fileId;
            break;
          case "app":
            this.useApp(datas.row);
            break;
          case "edit":
            this.editAppImage(datas.row);
            break;
          case "delete":
            this.$confirm(
              this.$t("message.deleteConfirm"),
              this.$t("commons.warning"),
              {
                confirmButtonText: this.$t("commons.confirm"),
                cancelButtonText: this.$t("commons.cancel"),
                type: "warning",
              }
            )
              .then(() => {
                this.deleteApp(datas.row.appImageId);
              })
              .catch((error) => {
                console.log(`未删除，原因 => ${error}`);
              });
            break;
        }
      }
    },
    // 删除
    deleteApp(appImageId) {
      deleteAppImage({ appImageId: appImageId })
        .then(() => {
          // 成功提示
          this.$message({
            message: this.$t("message.deleteSuccess"),
            type: "success",
          });
          this.findAppImagePage();
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    // 修改
    editAppImage(row) {
      this.currentState = "edit";
      this.driver.title = this.$t("vlink.app.editAppImage");
      this.driver.form.appImageId = row.appImageId;
      this.driver.form.fileId = row.fileId;
      this.fileList[0] = {
        fileName: row.jarName,
        fileSize: row.fileSize,
        fileFormat: "jar",
      };
      this.driver.form.jarName = row.jarName;
      this.driver.form.description = row.description;
      this.driver.form.imageName = row.imageName;
      this.driver.form.tagName = row.tagName;
      this.driver.form.extendConfig = row.extendConfig;
      this.driver.addDialogVisible = true;
    },
    useApp(row) {
      let param = row;
      useAppImage(param)
        .then(() => {
          // 成功提示
          this.$message({
            message: this.$t("message.saveSuccess"),
            type: "success",
          });
        })
        .catch((err) => {
          console.log("错误信息：", err);
        });
    },
    findAppImagePage() {
      this.driver.listQuery.current = 1;
      this.dataset.pageVO.current = 1;
      this.findAppImage();
    },
    findAppImage() {
      this.loadingFlag = true;
      let param = this.driver.listQuery;
      findAppImage(param)
        .then((res) => {
          this.dataset.tableData = res.rows;
          this.dataset.pageVO.total = res.total;
        })
        .catch((err) => {
          console.log("错误信息：", err);
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    createAppImage() {
      this.currentState = "add";
      this.driver.title = this.$t("vlink.app.addAppImage");
      this.fileList = [];
      this.driver.form = {
        appImageId: null,
        fileId: null,
        fileSize: null,
        jarName: null,
        imageName: null,
        tagName: null,
        extendConfig: null,
        description: null,
      };
      const { appDriverName } = this.$route.query;
      this.driver.form.imageName = appDriverName;

      this.driver.addDialogVisible = true;
    },
    // 取消保存
    cancelDeviceSave() {
      this.driver.addDialogVisible = false;
    },
    handleDeviceSave() {
      this.$refs.deviceForm.validate((v) => {
        if (v) {
          if (this.driver.form.appImageId) {
            editAppImage(this.driver.form)
              .then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                this.cancelDeviceSave();
                this.findAppImagePage();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          } else {
            const { appId } = this.$route.query;
            this.driver.form.appId = appId;
            if (!this.driver.form.fileId) {
              this.$message.warning("镜像文件不能为空");
            } else {
              createAppImage(this.driver.form)
                .then(() => {
                  // 成功提示
                  this.$message({
                    message: this.$t("message.saveSuccess"),
                    type: "success",
                  });
                  this.cancelDeviceSave();
                  this.findAppImagePage();
                })
                .catch((error) => {
                  console.log(`保存失败，原因 => ${error}`);
                });
            }
          }
        }
      });
    },
    dealFileItems() {
      this.fileList.forEach((item) => {
        item.fileName = item.displayName.replace(/(.*\/)*([^.]+).*/gi, "$2");
        item.fileFormat = item.displayName.replace(/.+\./, "");
      });
    },
    size2Str,
    uploadSuccess(files) {
      this.fileList.push(files[0]);
      // 处理item.displayName
      this.dealFileItems();
      this.$message({
        type: "success",
        message: this.$t("message.uploadSuccess"),
      });
      this.uploadLoading = false;
      const { fileId, fileSize, displayName } = files[0];
      this.driver.form.fileId = fileId;
      this.driver.form.fileSize = fileSize;
      this.driver.form.jarName = displayName;
    },
    uploadFail() {
      this.$message({ type: "error", message: this.$t("message.uploadFial") });
      this.uploadLoading = false;
    },
    fileSingleDelete(index) {
      this.fileList.splice(index, 1);
      this.driver.form.jarName = null;
    },
    beforeUpload(file) {
      this.uploadLoading = true;
      const isLt2M = file.size / 1024 / 1024 < 100;
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 100MB!");
        this.uploadLoading = false;
        return false;
      }
    },
  },
};
</script>

<style scoped lang="less">
.app-container {
  .temolate-list-container {
    .title-line {
      border-bottom: 1px solid #ebeef5;
      display: flex;
      & > span {
        flex: 1;
        text-align: center;
        color: #909399;
        font-weight: 500;
      }
    }
    .no-data-line {
      text-align: center;
      border-bottom: 1px solid #e8e8e8;
    }
    .detail-line {
      border-bottom: 1px solid #ebeef5;
      display: flex;
      & > span {
        flex: 1;
        text-align: center;
      }
      i {
        cursor: pointer;
      }
    }
  }
  .avatar-uploader {
    ::v-deep .el-upload {
      width: 100%;
    }
    .upload-handler-line {
      border-bottom: 1px solid #ebeef5;
      text-align: center;
      i {
        margin-right: 5px;
      }
    }
  }
}
</style>